@charset "UTF-8";
/* Google Font Import - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  outline: none;
}

input {
  outline: none;
}

:root {
  /* ===== Colors ===== */
  --body-color: #e4e9f7;
  --sidebar-color: #fff;
  --primary-color: #00e1ff;
  --primary-color-50: #00e1ff;
  --blue-color: #00e1ff;
  --pink-color: #ff00cd;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #252525;
  --buttons-color: #ff00cd;
  --input-font: #ff00cd;
  --input-color: #252525;
  --input-color-2: #252525;
  --primary-color2: #ff00cd;
  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}

html,
body,
div#root {
  height: 100%;
}

body {
  background-color: #e4e9f7;
}

html {
  --border: #e2e8f0;
}

html.dark {
  --border: #1f242f;
}
html.dark body {
  background-color: #101921;
}

.layout {
  transition: var(--tran-05);
  min-height: 100%;
}

::selection {
  background-color: var(--primary-color);
  color: #fff;
}

.layout.dark {
  --body-color: #101921;
  --sidebar-color: #0c111d;
  --primary-color: #ff00cd;
  --primary-color-50: #ff00cd;
  --pink-color: #ff00cd;
  --primary-color-light: #193148;
  --toggle-color: #fff;
  --text-color: #f5f5f5;
  --buttons-color: #00e1ff;
  --input-color: #5f8cb4;
  --input-color-2: #252525;
  --input-font: #00e1ff;
  --primary-color2: #00e1ff;
}

/* ===== Sidebar ===== */
.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 250px;
  padding: 10px 14px;
  background: var(--sidebar-color) !important;
  transition: var(--tran-05);
  z-index: 100;
  overflow: hidden;
}
.sidebar .menu-bar {
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar.close {
  width: 88px;
  left: -88px;
}
.sidebar.close li {
  width: 60px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .sidebar.close {
    left: 0;
  }
}
.sidebar.close li ul p {
  opacity: 0;
}
.sidebar.close .menu-bar {
  overflow: hidden;
}

/* ===== Reusable code - Here ===== */
.sidebar li {
  height: 30px;
  width: 220px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.sidebar li.nav-group {
  height: auto;
}
.sidebar li ul p {
  color: var(--primary-color);
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  margin: 1.4rem 0 0 1.4rem;
}
.sidebar li ul p svg {
  color: var(--pink-color);
}

.sidebar header .image,
.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
}

.sidebar .text,
.sidebar .icon {
  color: var(--text-color);
}

.sidebar .text {
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 1;
}

.sidebar.close .text {
  opacity: 0;
}

/* =========================== */
.sidebar header {
  position: relative;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
}

.sidebar header .logo-text {
  display: flex;
  flex-direction: column;
}

header .image-text .name {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 600;
}

header .image-text .profession {
  font-size: 16px;
  margin-top: -2px;
  display: block;
}

.sidebar header .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar header .image img {
  width: 40px;
  border-radius: 6px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}

.sidebar .menu {
  margin-top: 0px;
}

.sidebar li.search-box {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar li.search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--primary-color-light);
  color: var(--text-color);
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
  transition: var(--tran-05);
}

.sidebar li a {
  font-size: 14px;
  list-style: none;
  height: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: var(--tran-03);
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--sidebar-color);
}

.layout.dark .sidebar li a:hover .icon,
.layout.dark .sidebar li a:hover .text {
  color: var(--text-color);
}

.sidebar .menu-bar {
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  scrollbar-width: none;
}

.menu-bar::-webkit-scrollbar {
  display: none;
}

.sidebar .menu-bar .mode {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  position: relative;
  transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
  width: 60px;
}

.mode .sun-moon i {
  position: absolute;
}

.mode .sun-moon i.sun {
  opacity: 0;
}

.layout.dark .mode .sun-moon i.sun {
  opacity: 1;
}

.layout.dark .mode .sun-moon i.moon {
  opacity: 0;
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--toggle-color);
  transition: var(--tran-05);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
  transition: var(--tran-04);
}

.layout.dark .switch::before {
  left: 20px;
}

.home {
  background-color: var(--body-color);
  transition: var(--tran-05);
}
.home .dashboard .item {
  border-radius: 6px;
  background-color: var(--sidebar-color);
}
.home .googlemap {
  height: 80dvh;
  width: 100%;
}

.home .text {
  font-size: 30px;
  font-weight: 500;
  color: var(--text-color);
  padding: 12px 60px;
}

.layout.dark .home .text {
  color: var(--text-color);
}

.inputWrapper {
  background-color: var(--primary-color-light) !important;
}

.item-title {
  margin-left: 5px;
}

.item-count text {
  color: var(--text-color);
}

.item-styled {
  color: var(--text-color) !important;
  cursor: pointer;
}

.search {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
}
.search .row {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .search .row {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
.search .row > div {
  margin: 1rem 1rem 0 0;
}
@media (min-width: 768px) {
  .search .row > div {
    flex: 0 23%;
  }
}

.selected_data {
  font-size: 0.8rem;
}
.selected_data .selected_label {
  font-weight: 500;
  color: var(--text-color);
  padding: 0.2rem 0.4rem;
  border-radius: 6px;
  background-color: var(--primary-color-50);
}
.selected_data span {
  color: var(--text-color);
}

.react-select.react-select--compact .Select-value:first-child {
  font-style: italic;
}

.react-select.react-select--compact .Select-value:first-child .Select-value-icon,
.react-select.react-select--compact .Select-value:nth-child(n+2) {
  display: none;
}

.map-popup-body {
  padding: 0.4rem 0.6rem;
  display: flex;
  flex-direction: column;
}
.map-popup-body .title {
  color: var(--text-color);
  font-size: 1rem;
  margin-bottom: 0.2rem;
}
.map-popup-body .title.overformed {
  font-weight: bold;
  margin-bottom: 1rem;
}

.button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border-radius: 6px;
  padding: 0.4rem 0.7rem;
  font-size: 1rem;
  border: 3px solid var(--primary-color);
}
.button:hover {
  border: 3px solid var(--pink-color);
  color: var(--pink-color);
  background-color: transparent;
  cursor: pointer;
}

.database_wrapper .info {
  color: var(--text-color);
}
.database_wrapper .items .map-container .googlemap {
  height: 100%;
  width: 100%;
}
.database_wrapper .items .actions {
  justify-self: flex-end;
  position: absolute;
  right: 2rem;
}

.left-custom-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  z-index: 1;
  position: absolute;
  margin-top: 100px;
}

.map_button {
  background: var(--sidebar-color);
  width: 48px;
  height: 36px;
  border: none;
 fill: var(--text-color);
}

.on_map_icon {
  color: var(--text-color);
}

.column {
  float: left;
  width: 25%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox_label {
  color: var(--input-color);
}

.search_button {
  font-size: 18px;
  align-items: center;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-size: 16px;
  font-weight: 500;
  height: 38px;
  justify-content: center;
  letter-spacing: -0.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
}

.search_button:active {
  box-shadow: none;
  transform: translateY(-4px);
}

.hide_button {
  font-size: 18px;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-size: 16px;
  font-weight: 500;
  height: 38px;
  justify-content: center;
  letter-spacing: -0.8px;
  line-height: 24px;
  min-width: 140px;
  width: 100%;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
}

.hide_button:active {
  box-shadow: none;
  transform: translateY(-4px);
}

.keyword_search {
  width: 100%;
  background-color: var(--primary-color-light);
  border-color: #376187;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  justify-content: space-between;
  height: 36px;
  outline: 0 !important;
  padding: 2px 8px;
  box-sizing: border-box;
}

.keyword_search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--input-color);
  opacity: 1;
  /* Firefox */
}

.keyword_search:focus {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  border-color: var(--primary-color);
  opacity: 1;
  /* Firefox */
}

.myDatepicker_start:focus {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  border-color: var(--primary-color);
  opacity: 1;
  /* Firefox */
}

.myDatepicker_end:focus {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  border-color: var(--primary-color);
  opacity: 1;
  /* Firefox */
}

input {
  color: var(--input-color);
}

.myDatepicker_start,
.myDatepicker_end {
  width: 100%;
  background-color: var(--primary-color-light);
  color: var(--primary-color);
  border-color: #376187;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  padding: 2px 8px;
  box-sizing: border-box;
}

.bar {
  fill: var(--primary-color);
}

.shodan-bar {
  fill: var(--primary-color2);
}

.pie_labels {
  font-size: 1rem;
  fill: var(--text-color);
}

.main_title {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--text-color);
  margin: auto;
}

.progress {
  margin: 0 auto;
  margin-left: -3%;
  width: 100%;
}

.progress {
  padding: 4px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
  height: 20px;
  border-radius: 4px;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
  -webkit-transition-property: width, background-color;
  -moz-transition-property: width, background-color;
  -o-transition-property: width, background-color;
  transition-property: width, background-color;
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
  background-color: var(--primary-color2);
}

.tooltip {
  padding: 0px 20px;
  background: var(--sidebar-color);
  color: var(--text-color);
  position: absolute;
  top: -140%;
  left: -25%;
  border-radius: 9px;
  font-size: 10px;
  transform: translateY(9px);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 3px var(--primary-color2);
}

/* tooltip  after*/
.tooltip::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12.5px 0 12.5px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  left: 40%;
}

.pie_lines {
  stroke: var(--text-color);
  fill: none;
  stroke-width: 1px;
}

.request_title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-color);
  margin: auto;
}

.location_input {
  border: 1px solid var(--input-color-2);
  color: var(--input-color-2);
  border-radius: 0.3rem;
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
  margin: 0.4rem 0 1rem 0;
}

.sliderwrapper {
  margin-bottom: 2rem;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.sliderwrapper .rc-slider-mark-text {
  width: max-content;
}

.loader {
  background: #0e1a25;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader img {
  width: 50px;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.text-small {
  color: var(--text-color);
}

.selected_data {
  position: absolute;
}

#react-select-1-listbox,
#react-select-2-listbox,
#react-select-3-listbox,
#react-select-4-listbox,
#react-select-5-listbox,
#react-select-6-listbox,
#react-select-7-listbox,
#react-select-8-listbox,
#react-select-9-listbox {
  z-index: 5000;
}

.database-search {
  color: var(--text-color);
  padding: 0.8rem 1.2rem;
  background: var(--primary-color);
  border-radius: 6px;
  margin-left: 1rem;
  font-weight: 500;
  text-decoration: none;
}

.profile {
  position: relative;
  background: var(--sidebar-color);
  color: var(--text-color);
  box-shadow: 0 3px 15px rgba(51, 51, 51, 0.2);
  transition: 0.2s ease;
}
.profile__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile__stats__title {
  color: var(--grayColor);
  text-transform: uppercase;
  font-size: 16px;
  word-wrap: break-word;
}
.profile a:hover {
  top: 0;
  box-shadow: none;
  background: var(--text-color);
}
.profile a:active, .profile a:focus {
  top: 0;
  box-shadow: none;
  background: var(--text-color);
}

.profile-bigimg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 300px auto 0px;
  overflow: hidden;
  margin: auto;
}
.profile-bigimg .profile__image {
  grid-column: span 3;
}
.profile-bigimg .profile__info {
  grid-column: span 2;
  align-self: center;
  padding: 20px 10px 20px 25px;
}
.profile-bigimg .profile__info h3 {
  margin-bottom: 0;
}
.profile-bigimg .profile__info__extra {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease 0.3s, margin 0.3s ease;
}
.profile-bigimg .profile__cta {
  padding: 20px 20px 20px 0;
}
.profile-bigimg:hover .profile__info__extra {
  display: block;
  max-height: 200px;
  margin: 10px 0 0;
  opacity: 1;
}

.profile-default {
  min-width: 400px;
  max-width: 450px;
  margin: auto;
  overflow: hidden;
  display: grid;
  grid: 340px auto auto/repeat(3, minmax(80px, 1fr));
  grid-gap: 10px;
}
.profile-default .profile__image {
  grid-column: span 3;
}
.profile-default .profile__image img {
  border-bottom: 7px solid var(--secondaryColor);
}
.profile-default .profile__info {
  grid-column: span 3;
  margin: 20px 25px 0;
}
.profile-default .profile__stats {
  margin: 5px 25px;
}
.profile-default .profile__cta {
  grid-column: span 3;
  margin: 0 25px 25px;
}

.search_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search_items input[type=text] {
  width: 50%;
  padding: 10px;
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.search_items .select {
  width: 25%;
  margin-left: auto;
  text-align: right;
}
.search_items .export_button {
  width: 25%;
  text-align: right;
}

select {
  /* Reset Select */
  appearance: none;
  outline: 10px red;
  font-size: 20px;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  color: var(--text-color);
  background-color: var(--sidebar-color);
  cursor: pointer;
}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: 0.25em;
  overflow: hidden;
}

/* Arrow */
.select::after {
  content: "▼";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: 0.25s all ease;
  pointer-events: none;
}

/* Transition */
.select:hover::after {
  color: var(--primary-color);
}

.limit-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.search_post {
  width: 100%;
  background: var(--sidebar-color);
  padding: 0 1.6rem;
  transition: all 0.3s cubic-bezier(0, 0, 0.43, 1.49);
  z-index: 1;
  position: relative;
}

.search_post:not(:placeholder-shown) {
  width: calc(100% - 6rem);
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  padding: 10 20 20 10;
  flex-wrap: wrap;
}
.pagination .page-item {
  margin: 0 5px;
}
.pagination .page-item .page-link {
  text-decoration: none;
  color: var(--primary-color);
  padding: 5px 10px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
}
.pagination .page-item .page-link:hover {
  background-color: var(--primary-color);
  color: #fff;
}
.pagination .page-item.active .page-link {
  background-color: var(--primary-color);
  color: #fff;
}

.gm-style-iw {
  background-color: var(--body-color) !important;
}

.gm-style-iw-d,
.gm-style-iw-c {
  overflow: hidden !important;
  min-height: max-content !important;
  min-width: max-content !important;
  max-height: none !important;
  max-width: none !important;
}

.gm-style .gm-style-iw-c {
  padding: 12px !important;
}

.gm-ui-hover-effect span {
  background-color: white !important;
}

.gm-style-iw-tc::after {
  background-color: var(--body-color) !important;
}

.poi-info-window * {
  background-color: var(--body-color) !important;
  color: var(--text-color) !important;
}
.poi-info-window * .title {
  color: var(--text-color) !important;
}

.view-link {
  color: #1a73e8 !important;
}
.view-link span {
  color: #1a73e8 !important;
}
.view-link a {
  color: #1a73e8 !important;
  text-decoration-color: #1a73e8 !important;
}

.pricing-plans {
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.pricing-card {
  --col: #e4e4e7;
  position: relative;
  padding: 32px 64px;
  padding-bottom: 64px;
  border-radius: 4px;
  background-color: var(--sidebar-color);
  text-align: center;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-card.basic {
  --col: #ff00cd;
}

.pricing-card.standard {
  --col: #00e1ff;
}

.pricing-card.premium {
  --col: #293f73;
}

.pricing-card.hover:hover {
  border-color: var(--col);
  transform: translateY(-12px) scale(1.01);
}

.pricing-card > *:not(:last-child) {
  margin-bottom: 32px;
}

.pricing-card .heading h4 {
  padding-bottom: 12px;
  color: var(--col);
  font-size: 24px;
  font-weight: 500;
}

.pricing-card .heading p {
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.pricing-card .price {
  position: relative;
  color: var(--col);
  font-size: 60px;
  font-weight: bold;
}

.pricing-card .price sub {
  position: absolute;
  bottom: 14px;
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.pricing-card .features li {
  padding-bottom: 24px;
  text-align: left;
  display: flex;
  color: var(--text-color);
  align-items: center;
  gap: 8px;
}
.pricing-card .features li span {
  font-size: 14px;
  opacity: 0.75;
}
.pricing-card .features li strong {
  font-size: 16px;
  font-weight: 500;
  line-height: 110%;
  padding-left: 12px;
  padding-right: 4px;
}
@media screen and (min-width: 768x) {
  .pricing-card .features li {
    align-self: flex-start;
    padding-left: 12px;
    padding-right: 0;
  }
}

.pricing-card .cta-btn {
  padding: 12px 24px;
  border-radius: 4px;
  border: 1px solid var(--col);
  background-color: var(--col);
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease;
}

.pricing-card .cta-btn:hover {
  background-color: var(--sidebar-color);
  color: var(--col);
}

.infoBox {
  transform: translateX(-50%) translateY(-50%) !important;
}

.infoBox img {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.gm-style-mtc button,
.gm-style-mtc ul,
.gm-style-mtc li {
  background-color: var(--sidebar-color) !important;
}
.gm-style-mtc button,
.gm-style-mtc li {
  color: var(--text-color) !important;
}

.layout.dark .gm-style-mtc button,
.layout.dark .gm-style-mtc ul,
.layout.dark .gm-style-mtc li {
  background-color: var(--body-color) !important;
}

.gm-style iframe + div {
  border: none !important;
}

.gm-style-iw.gm-style-iw-c[aria-label=custom-window-marker] div.gm-style-iw-chr {
  position: absolute;
  display: flex !important;
  align-items: center !important;
  right: 16px;
  left: 16px;
  top: 14px;
  z-index: 50;
}
.gm-style-iw.gm-style-iw-c[aria-label=custom-window-marker] div.gm-style-iw-chr > button {
  height: 24px !important;
  width: 24px !important;
}
.gm-style-iw.gm-style-iw-c[aria-label=custom-window-marker] div.gm-style-iw-chr > button span {
  margin: 0px !important;
}

.gm-style-iw.gm-style-iw-c[aria-label=no-close-window-marker] div.gm-style-iw-chr {
  display: none !important;
}

div.gm-style-iw-chr > button {
  height: 24px !important;
  width: 24px !important;
}
div.gm-style-iw-chr > button span {
  margin: 0px !important;
}
div.gm-style-iw-chr > div.gm-style-iw-ch {
  padding: 0px !important;
  display: flex;
  align-items: center;
}

div.gm-title {
  background-color: var(--body-color) !important;
  color: var(--text-color) !important;
}

.video-js {
  width: 100% !important;
}

.tweet-container_root__SV1FQ {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

